import type { MainPageResponse } from '~/types/MainPage';

export const useMainPageStore = defineStore('main-page', {
  actions: {
    async getMainPage() {
      try {
        const { data } = await useCustomFetch<{ data: MainPageResponse }>('/info/main-page');

        if (data.value) {
          return data.value.data;
        } else {
          return null;
        }
      } catch (e) {
        if (e && process.env.NODE_ENV !== 'production') {
          console.error(e);
        }
        return null;
      }
    },
  },
});
