<script lang="ts" setup>
import { Autoplay, FreeMode, Mousewheel } from 'swiper/modules';
import { Swiper } from 'swiper';
import type { Ref } from 'vue';
import type { Swiper as SwiperType } from 'swiper/types';
import type { BrandsGallery } from '~/types/MainPage';

defineProps<{ data: BrandsGallery }>();

onMounted(() => {
  sliderInitialization();
});

const brandsSwiper: Ref<SwiperType | null> = ref(null);
const brandsSwiperRef = ref();

function sliderInitialization() {
  brandsSwiper.value = new Swiper(brandsSwiperRef.value, {
    autoplay: { delay: 5000, pauseOnMouseEnter: true },
    breakpoints: {
      768: {
        spaceBetween: 16,
        slidesOffsetBefore: 0,
      },
    },
    freeMode: { sticky: true },
    loop: true,
    loopAddBlankSlides: true,
    modules: [Autoplay, FreeMode, Mousewheel],
    mousewheel: { forceToAxis: true },
    slidesOffsetBefore: 12,
    slidesPerView: 'auto',
    spaceBetween: 12,
    speed: 1000,
  });
}
</script>

<template>
  <section class="brands-gallery">
    <AppLink
      class="w-fit px-3 lg:px-0"
      to="/brands"
    >
      <h2 class="inline underline underline-offset-4">Бренды</h2>
    </AppLink>

    <div
      ref="brandsSwiperRef"
      class="swiper w-full"
    >
      <div class="swiper-wrapper">
        <div
          v-for="brand in data.brands"
          :key="brand.slug"
          class="swiper-slide !w-fit"
        >
          <AppLink :to="brand.uri">
            <BrandLogoBlock
              :brand
              class="brands-gallery__item"
              show-title
            />
          </AppLink>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.brands-gallery {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include screen-lg {
    @include inner-content;
  }
}
</style>
