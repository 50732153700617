export const MAIN_PAGE_BLOCK_TYPES = {
  BRANDS: 'brands',
  GALLERY_BANNERS: 'gallery_banners',
  GALLERY_UNIFIED: 'gallery_unified',
  BLOG: 'blog',
  PRODUCT_GALLERY: 'product_gallery',
  RECOMMENDATION_BLOCK: 'recommendation_block',
  SPECIAL_OFFERS: 'special_offers',
  TILES: 'tiles',
};
