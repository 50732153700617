<script lang="ts" setup>
import { MAIN_PAGE_BLOCK_TYPES } from '~/constants/mainPageBlockTypes';
import type { BrandsGallery, Datum, Galleries, MainPageResponse } from '~/types/MainPage';
import type { StaticPagesListItem } from '~/types/StaticPageList';
import type { OfferMedium, RecommendationsResponse } from '~/types/NewTypes/CatalogNew';

const pageData = ref<MainPageResponse | null>(null);
const catalogStore = useCatalogStore();
const mainPageStore = useMainPageStore();

pageData.value = await mainPageStore.getMainPage();

useHead({
  title: pageData.value?.seo_data.title,
  meta: [
    { name: 'description', content: pageData.value?.seo_data.description },
    { name: 'canonical', content: pageData.value?.seo_data.canonical },
    { name: 'text', content: pageData.value?.seo_data.text },
    { name: 'keywords', content: pageData.value?.seo_data.keywords },
    { property: 'og:title', content: pageData.value?.seo_data.title },
    { property: 'og:description', content: pageData.value?.seo_data.description },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: pageData.value?.seo_data.canonical },
    { property: 'og:site_name', content: 'Hellride.ru' },
    { property: 'og:locale', content: 'ru_RU' },
    { property: 'og:image', content: pageData.value?.seo_data.image?.conversions.image_open_graph_thumb },
  ],
});

useJsonld({
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: 'Hellride',
  url: 'https://www.hellride.ru/',
  potentialAction: {
    '@type': 'SearchAction',
    target: {
      '@type': 'EntryPoint',
      urlTemplate: 'https://www.hellride.ru/search?filter[search]={search_term_string}',
    },
    'query-input': 'required name=search_term_string',
  },
});

function getStaticPageCardGalleryTitle(type: string) {
  switch (type) {
    case MAIN_PAGE_BLOCK_TYPES.SPECIAL_OFFERS:
      return 'Акции';
    case MAIN_PAGE_BLOCK_TYPES.BLOG:
      return 'Блог';
    default:
      return null;
  }
}

function getStaticPageCardGalleryLink(type: string) {
  switch (type) {
    case MAIN_PAGE_BLOCK_TYPES.SPECIAL_OFFERS:
      return '/special-offers';
    case MAIN_PAGE_BLOCK_TYPES.BLOG:
      return '/blog';
    default:
      return null;
  }
}

function getBlockNameForDataLayer(blockType: string) {
  switch (blockType) {
    case MAIN_PAGE_BLOCK_TYPES.SPECIAL_OFFERS:
      return 'current_promotions';
    case MAIN_PAGE_BLOCK_TYPES.BLOG:
      return 'home_news';
    default:
      return undefined;
  }
}

onMounted(() => {
  if (import.meta.client && window.r46) {
    for (const block of pageData.value?.layout_object || []) {
      if (block.data.recommendation_block_id) {
        window.r46(
          'recommend',
          block.data.recommendation_block_id,
          {},
          async function (response: RecommendationsResponse) {
            if (!response.recommends.length) return;

            block.data.offers = await catalogStore.getOffersList(response.recommends.join(','));
            block.data.title = `<h2>${response.title}</h2`;
          },
        );
      }
    }
  }
});
</script>

<template>
  <div
    v-if="pageData"
    class="main-page"
  >
    <template
      v-for="block in pageData?.layout_object"
      :key="block.order"
    >
      <MainBannerGallery
        v-if="block.type === MAIN_PAGE_BLOCK_TYPES.GALLERY_BANNERS"
        :data="block.data as Galleries"
      />

      <BrandsGallery
        v-else-if="block.type === MAIN_PAGE_BLOCK_TYPES.BRANDS"
        :data="block.data as BrandsGallery"
      />

      <template v-else-if="block.type === MAIN_PAGE_BLOCK_TYPES.PRODUCT_GALLERY">
        <ProductCardsGallery
          v-for="(item, key) in block.data as Datum[]"
          :key="`${item.title}-${key}`"
          :offers="item?.products as OfferMedium[]"
          :title="item?.title"
        />
      </template>

      <ProductCardsGallery
        v-else-if="block.type === MAIN_PAGE_BLOCK_TYPES.RECOMMENDATION_BLOCK && block.data.offers?.length"
        :offers="block.data.offers as OfferMedium[]"
        :title="block.data?.title"
      />

      <StaticPageCardGallery
        v-else-if="block.type === MAIN_PAGE_BLOCK_TYPES.SPECIAL_OFFERS || block.type === MAIN_PAGE_BLOCK_TYPES.BLOG"
        :block-name="getBlockNameForDataLayer(block.type)"
        :is-show-annotation="block.type === MAIN_PAGE_BLOCK_TYPES.SPECIAL_OFFERS"
        :items="block.data as StaticPagesListItem[]"
        :link="getStaticPageCardGalleryLink(block.type)"
        :title="getStaticPageCardGalleryTitle(block.type)"
        is-observe
      />

      <Tiles
        v-else-if="block.type === MAIN_PAGE_BLOCK_TYPES.TILES"
        :data="block.data as Galleries"
      />

      <UnifiedGallery
        v-else-if="block.type === MAIN_PAGE_BLOCK_TYPES.GALLERY_UNIFIED"
        :data="block.data as Galleries"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.main-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 52px;

  @include screen-sm {
    gap: 60px;
  }

  @include screen-md {
    gap: 80px;
  }
}
</style>
